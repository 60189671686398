import Dashboard from "views/system/Dashboard.jsx";
import Applications from "views/spots/Applications.jsx";
import Actions from "views/system/Actions.jsx";
import Banners from "views/system/Banners.jsx";
import Transactions from "views/system/Transactions.jsx";
import Transaction from "views/system/Transaction.jsx";
import Developer from "views/system/Developer.jsx";
import Global from "views/system/Globals.jsx";
import RiskEngine from "views/system/RiskEngine.jsx";
import FAQ from "views/system/Faq.jsx";
import Bookings from "views/users/Bookings.jsx";
import Referrals from "views/users/Referrals.jsx";
import ContactUs from "views/system/ContactUs.jsx";
import Users from "views/users/Users.jsx";
import UserOverview from "views/users/UserOverview.jsx";
import Reports from "views/spots/Reports.jsx";
import ReportOverview from "views/spots/ReportOverview.jsx";
import Feedback from "views/system/Feedback.jsx";
import Spots from "views/spots/Spots.jsx";
import SpotOverview from "views/spots/SpotOverview.jsx";
import Login from "views/auth/Login.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    show: true
  },
  {
    path: "/escalation",
    name: "Action needed",
    rtlName: "Action needed",
    icon: "tim-icons icon-alert-circle-exc",
    component: Actions,
    layout: "/admin",
    show: true
  },
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: "Transactions",
    icon: "tim-icons icon-coins",
    component: Transactions,
    layout: "/admin",
    show: true
  },
  {
    path: "/transaction",
    name: "Transaction",
    rtlName: "Transaction",
    icon: "tim-icons icon-coins",
    component: Transaction,
    layout: "/admin",
    show: false
  },
  {
    path: "/globals",
    name: "Globals",
    rtlName: "Globals",
    icon: "tim-icons icon-world",
    component: Global,
    layout: "/admin",
    show: true
  },
  {
    path: "/risk",
    name: "Risk Engine",
    rtlName: "Risk Engine",
    icon: "tim-icons icon-lock-circle",
    component: RiskEngine,
    layout: "/admin",
    show: true
  },
  {
    path: "/applications",
    name: "Applications",
    rtlName: "Applications",
    icon: "tim-icons icon-app",
    component: Applications,
    layout: "/admin",
    show: true
  },
  {
    path: "/banners",
    name: "Banners",
    rtlName: "Banners",
    icon: "tim-icons icon-bulb-63",
    component: Banners,
    layout: "/admin",
    show: true
  },
  {
    path: "/bookings",
    name: "Bookings",
    rtlName: "Bookings",
    icon: "tim-icons icon-book-bookmark",
    component: Bookings,
    layout: "/admin",
    show: true
  },
  {
    path: "/contact",
    name: "Contact Us",
    rtlName: "Contact Us",
    icon: "tim-icons icon-email-85",
    component: ContactUs,
    layout: "/admin",
    show: true
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "Users",
    icon: "tim-icons icon-user-run",
    component: Users,
    layout: "/admin",
    show: true
  },
  {
    path: "/user",
    name: "User",
    rtlName: "User",
    icon: "tim-icons icon-user-run",
    component: UserOverview,
    layout: "/admin",
    show: false
  },
  {
    path: "/referrals",
    name: "Referrals",
    rtlName: "Referrals",
    icon: "tim-icons icon-money-coins",
    component: Referrals,
    layout: "/admin",
    show: true
  },
  {
    path: "/referral",
    name: "Referral",
    rtlName: "Referral",
    icon: "tim-icons icon-alert-circle-exc",
    component: Reports,
    layout: "/admin",
    show: false
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "Reports",
    icon: "tim-icons icon-alert-circle-exc",
    component: Reports,
    layout: "/admin",
    show: true
  },
  {
    path: "/report",
    name: "Report",
    rtlName: "Report",
    icon: "tim-icons icon-alert-circle-exc",
    component: ReportOverview,
    layout: "/admin",
    show: false
  },
  {
    path: "/feedback",
    name: "Feedback",
    rtlName: "Feedback",
    icon: "tim-icons icon-email-85",
    component: Feedback,
    layout: "/admin",
    show: true
  },
  {
    path: "/faq",
    name: "FAQ",
    rtlName: "FAQ",
    icon: "tim-icons icon-email-85",
    component: FAQ,
    layout: "/admin",
    show: true
  },
  {
    path: "/spots",
    name: "Spots",
    rtlName: "Spots",
    icon: "tim-icons icon-bus-front-12",
    component: Spots,
    layout: "/admin",
    show: true
  },
  {
    path: "/developer",
    name: "Developer",
    rtlName: "Developer",
    icon: "tim-icons icon-controller",
    component: Developer,
    layout: "/admin",
    show: true
  },
  {
    path: "/details",
    name: "Spot Detail",
    rtlName: "Spot Detail",
    icon: "tim-icons icon-bus-front-12",
    component: SpotOverview,
    layout: "/admin",
    show: false
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "Login",
    icon: "tim-icons icon-bus-front-12",
    component: Login,
    layout: "/auth",
    show: false
  },
];
export default routes;
