

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import Button from 'react-bootstrap/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as Sentry from "@sentry/react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import Grid from '@mui/material/Grid';
import { ThemeContext } from "../../contexts/ThemeContext"
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../../services/firebase";
import { doc, getDoc, collection, query, where, updateDoc } from "firebase/firestore";
import { firestoreKeys } from '../../utils/constants';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AuthContext } from "../../contexts/AuthContext";

function Transaction(props) {
  const { user, setUser } = React.useContext(AuthContext);
  let history = useHistory();
  const location = useLocation();
  console.log("State got:", location)
  const theme = useContext(ThemeContext);

  const [transaction, setTransaction] = useState(location.state.transaction)
  const metadataJson = JSON.stringify(transaction.metadata, null, 2);

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([])


  const containerStyle = {
    width: '100%',
    minHeight: '80%'
  };
  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const removeUser = async (email) => {
    console.log("Removing user")
  }

  const sendInvite = async () => {
    console.log("Adding dependent")
  }

  const [host, setHost] = useState({})

  useEffect(() => {
    let message = (<div>
      <div>
        Could not load corresponding data
      </div>
    </div>)



    const getRelatedData = async () => {
      const docRef = doc(db, "users", transaction.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setHost(docSnap.data())
      } else {
        console.log("NO user")
        Sentry.captureException("No such document for user: ", transaction.uid);
        console.log(host)
        notify("tl", message, "danger")
      }
      setLoaded(true)
    }

    getRelatedData()

  

  }, [])

  const [parkingStatus, setParkingStatus] = useState(transaction.status);

  const handleChange = (event) => {
    setParkingStatus(event.target.value);
  };

  const [adminNotes, setAdminNotes] = useState(transaction.adminNotes)
  const [adminHostChanged, setAdminHostChanged] = useState(false)
  const handlePaymentUpdate = () => {
    setLoaded(false)
    // if admin notes changed
    console.log(adminNotes !== undefined && adminHostChanged)
    if (adminNotes !== undefined && adminHostChanged) {
      console.log("Udpateign")
      const docRef = doc(db, firestoreKeys.TRANSACTIONS, transaction.pid);
      let updatedFields = { "adminNotes": adminNotes }
      updateDoc(docRef, updatedFields)
      .then(async docRef => {
          console.log("updated")
          setLoaded(true)
      })
      .catch(error => {
          console.log(error);
          setLoaded(true);
          // notify("tl", <p>{error}</p>, "danger")
      })
    }
   
    
    //Handle the request via api
    // let dataToSend = {
    //   "date": new Date(),
    //   "actions": "transaction_change",
    //   "user": user.email,
    //   "split": parseInt(referralValue)
    // }
    // await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
    setLoaded(true);
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {loaded ? (
        <>
          <div className="content">
            {transaction !== undefined ? (
              <>

                <Row>
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h1" style={{
                          fontSize: "1.5rem", background:
                            "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip:
                            "text", WebkitTextFillColor:
                            "transparent", fontWeight: 500
                        }}>transaction details</CardTitle>
                        <p className="category" style={{ fontSize: "1rem" }}>Details linked. &nbsp;</p>


                      </CardHeader>
                      <CardBody>

                        <Form>
                          <Row>
                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Paystack ID</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.pid}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Amount</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.amount}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>State</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.status}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>


                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Code</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.ppc}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Currency</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.currency}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Channel</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={transaction.channels}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={12} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  style={{ width: "100%" }}
                                  id="outlined-multiline-static"
                                  label="Metadata"
                                  multiline
                                  rows={4}
                                  disabled
                                  value={metadataJson}
                                  InputLabelProps={{ style: { fontSize: "1rem" } }}

                                />
                              </Form.Group>
                            </Col>




                            <Col lg={12} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Admin Notes"
                                  multiline
                                  rows={4}
                                  value={transaction.adminNotes}
                                  sx={{ marginRight: "13px", width: "100%" }}
                                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                                  onChange={(e)=>{
                                    setAdminHostChanged(true)
                                    setAdminNotes(e.target.value)
                                  }}
                                />
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={parkingStatus}
                                  label="Age"
                                  onChange={handleChange}
                                  sx={{ marginTop: "3vh" }}

                                >
                                  <MenuItem value={'pending'}>Pending</MenuItem>
                                  <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                                  <MenuItem value={'failed_matched'} disabled>Failed matched</MenuItem>
                                  <MenuItem value={'failed_unmatched'} disabled>Failed Unmatched</MenuItem>
                                  <MenuItem value={'refund'}>Refund</MenuItem>
                                  <MenuItem value={'refunded'} disabled>Refunded </MenuItem>
                                  <MenuItem value={'success'} >Success</MenuItem>
                                  <MenuItem value={'disputed'}>Disputed</MenuItem>
                                </Select>

                                <Button style={{ margin: "1vw", textAlign: "center" }} onClick={handlePaymentUpdate}>Save</Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>

                      </CardBody>
                    </Card>
                  </Col>
                  {/* Host */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4" style={{
                          fontSize: "1.3rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500
                        }}>User information</CardTitle>
                        <p className="category" style={{ fontSize: "1rem", marginTop: "2vh" }}>User information. &nbsp;</p>
                      </CardHeader>
                      <CardBody>

                        <Form>

                          <Row>
                            {
                              Object.keys(host).length === 0 ? (
                                <>
                                <Col xs={12}>
                                  <p> The user is not linked to the system anymore, but the email used was {transaction.email}</p>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Name</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={`${host.person.firstName || ''} ${host.person.lastName || ''}`}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Email</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={host.person.email}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Number</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={host.person.phoneNumber}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Button variant="primary" onClick={() => {
                                      history.push({
                                        pathname: `/user`,
                                        state: { user: host }
                                      })
                                    }} style={{}}>
                                      View User
                                    </Button>
                                  </Col>
                                </>
                              )
                            }
                          </Row>

                        </Form>

                      </CardBody>
                    </Card>
                  </Col>

                </Row>


              </>
            ) : (<>
              <Row >

                <Col md="12" >
                  <Card className="card-plain" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                  }} >
                    <CardHeader >
                      <CardTitle tag="h4">We could not find this transaction..</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <p className="category">If this is a mistake please contact IT</p><br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>)}

          </div>
        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Business Overview</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>

                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default Transaction;
