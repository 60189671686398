

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as Sentry from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ThreeCircles } from 'react-loader-spinner';
import NotificationAlert from "react-notification-alert";
import "../../assets/css/black-dashboard-react.css";
// reactstrap components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { collection, doc, getDoc, getDocs, query, updateDoc, where, deleteDoc } from "firebase/firestore";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card, CardBody, CardHeader, CardTitle, Col, Row, Table
} from "reactstrap";
import { ThemeContext } from "../../contexts/ThemeContext";
import { db } from "../../services/firebase";
import { firestoreKeys } from '../../utils/constants';


function SpotOverview(props) {

  let history = useHistory();

  const location = useLocation();
  console.log("State got:", location)
  const theme = useContext(ThemeContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN
  })

  const [spot, setSpot] = useState(location.state.spot)
  const [host, setHost] = useState({})
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseInvite = () => setShowInvite(false);
  // Invite
  const [alignment, setAlignment] = React.useState('viewer');
  const [email, setEmail] = useState("");
  const [showInvite, setShowInvite] = useState(false);
  // Map
  const [map, setMap] = useState(null)
  const [showMarker, setShowMarker] = useState(true)
  const [markerLong, setMarkerLong] = useState(spot.location.lng)
  const [markerLat, setMarkerLat] = useState(spot.location.lat)
  const [markers, setMarkers] = useState([])
  const [center, setCenter] = useState({
    lat: spot.location.lat,
    lng: spot.location.lng
  })
  const [rating, setRating] = useState(null);


  const containerStyle = {
    width: '100%',
    minHeight: '80%'
  };
  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updateUser = async () => {
    setShowInvite(true)

  };

  const removeUser = async (email) => {
    console.log("Removing user")
  }

  const sendInvite = async () => {
    console.log("Adding dependent")
  }

  useEffect(() => {
    let message = (<div>
      <div>
        Could not load corresponding data
      </div>
    </div>)

    const setSpotRating = () => {
      console.log("Setting rating", spot)
      if (
        // eslint-disable-next-line no-prototype-builtins
        spot.hasOwnProperty("rating")) {
        console.log("Set with", spot.rating?.average)
        setRating(spot.rating.average || 0)
      }
    }

    const getRelatedData = async () => {
      const docRef = doc(db, "users", spot.owner);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setHost(docSnap.data())
      } else {
        Sentry.captureException("No such document for user: ", spot.owner);
        notify("tl", message, "danger")
      }

      const tmpArray = []
      console.log("Getting for owner", spot.owner)
      
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.SPOTS), (where('owner', '==', spot.owner))));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.data());
        tmpArray.push(doc.data())
      });
      console.log(tmpArray)
      setItems(tmpArray)
      setLoaded(true)
    }

    setSpotRating()
    getRelatedData()

  }, [])

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setZoom(14)
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [adminNotes, setAdminNotes] = useState(spot.adminNotes)
  const [adminHostChanged, setAdminHostChanged] = useState(false)
  const [statusChanged, setStatusChanged] = useState(false)

  const [parkingStatus, setParkingStatus] = useState(spot.admin.state);

  const handleChange = (event) => {
    setParkingStatus(event.target.value);
    setStatusChanged(true)
  };

  const deleteSpot = () => {
    console.log("Deleting")
    const docRef = doc(db, firestoreKeys.SPOTS, spot.location.formal);
    deleteDoc(docRef).then(() => {
      console.log("Removed.")
    }).catch(err => {
      console.log("Error:", err)
      notify("tl", <p>{err}</p>, "danger")
    })
                 
  }

  // const createSubAccount = (bank_details) => {
  //   const https = require('https')

  //   const params = JSON.stringify({
  //     "business_name": "Sunshine Studios",
  //     "settlement_bank": "044",
  //     "account_number": "0193274682",
  //     "percentage_charge": 18.2
  //   })

  //   const options = {
  //     hostname: 'api.paystack.co',
  //     port: 443,
  //     path: '/subaccount',
  //     method: 'POST',
  //     headers: {
  //       Authorization: 'Bearer SECRET_KEY',
  //       'Content-Type': 'application/json'
  //     }
  //   }

  //   const req = https.request(options, res => {
  //     let data = ''

  //     res.on('data', (chunk) => {
  //       data += chunk
  //     });

  //     res.on('end', () => {
  //       console.log(JSON.parse(data))
  //     })
  //   }).on('error', error => {
  //     console.error(error)
  //   })

  //   req.write(params)
  //   req.end()
  // }


  const handlePaymentUpdate = () => {
    setLoaded(false)
    // if admin notes changed
    console.log(adminNotes !== undefined && adminHostChanged)
    if (adminNotes !== undefined && adminHostChanged) {
      console.log("Udpateign")
      const docRef = doc(db, firestoreKeys.SPOTS, spot.location.formal);
      let updatedFields = { "adminNotes": adminNotes }
      updateDoc(docRef, updatedFields)
        .then(async docRef => {
          console.log("updated")
          setLoaded(true)
        })
        .catch(error => {
          console.log(error);
          setLoaded(true);
          notify("tl", <p>{error}</p>, "danger")
        })
      // Once notes have been added check if the host linked has a host account
      if (statusChanged) {
        // Get the user
        if (host.permissions.admin !== true) {
          // need to update user to valid host
          // Get banking details to send to paysarcj
          // createSubAccount(host.banking)
        }
        // Update if needed
        updateDoc(docRef, updatedFields)
          .then(async docRef => {
            console.log("updated")
            setLoaded(true)
          })
          .catch(error => {
            console.log(error);
            setLoaded(true);
            notify("tl", <p>{error}</p>, "danger")
          })
      }
    }
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {loaded ? (
        <>
          <div className="content">
            {spot !== undefined ? (
              <>
{/* Host */}
<Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4" style={{
                          fontSize: "1.3rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500
                        }}>Host contact information</CardTitle>
                        <p className="category" style={{ fontSize: "1rem", marginTop: "2vh" }}>Host who owns the spot. &nbsp;</p>
                      </CardHeader>
                      <CardBody>

                        <Form>

                          <Row>
                            {
                              Object.keys(host).length === 0 ? (
                                <>
                                  <Col xs={12}>
                                    <p> The user is not linked to the system anymore. Do you want to delete the application? </p>
                                    <Button variant="secondary" onClick={deleteSpot} style={{ marginLeft: "20%" }}>
                                      Delete
                                    </Button>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Name</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={`${host.person.firstName || ''} ${host.person.lastName || ''}`}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Email</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={host.person.email}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      <Form.Label style={{ fontSize: "0.9rem", color: "#000" }}>Number</Form.Label>
                                      <Form.Control
                                        type="name"
                                        disabled
                                        value={host.person.phoneNumber}
                                        style={{ borderRadius: "0.2vw" }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} xs={12}>
                                    <Button variant="primary" onClick={() => {
                                      history.push({
                                        pathname: `/user`,
                                        state: { user: host }
                                      })
                                    }} style={{}}>
                                      View Host
                                    </Button>
                                  </Col>
                                </>)}

                          </Row>

                        </Form>

                      </CardBody>
                    </Card>
                  </Col>

                <Row>
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h1" style={{
                          fontSize: "1.5rem", background:
                            "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip:
                            "text", WebkitTextFillColor:
                            "transparent", fontWeight: 500
                        }}>Spot details</CardTitle>
                        <p className="category" style={{ fontSize: "1rem" }}>Details linked. &nbsp;</p>
                        {
                          rating !== null && (
                            <Rating name="read-only" value={rating} readOnly />
                          )
                        }

                      </CardHeader>
                      <CardBody>

                        <Form>
                          <Row>
                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Name</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={spot.name}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>Price</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={spot.price}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{ fontSize: "1rem", color: "#000" }}>State</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={spot.admin.state}
                                  style={{ backgroundColor: "#c7d3e233", borderRadius: "0.2vw" }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={12} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  style={{ width: "100%" }}
                                  id="outlined-multiline-static"
                                  label="Description"
                                  multiline
                                  rows={4}
                                  disabled
                                  value={spot.description}
                                  InputLabelProps={{ style: { fontSize: "1rem" } }}

                                />
                              </Form.Group>
                            </Col>

                            <Col lg={12} xs={12} style={{ marginTop: "3vh" }}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Admin Notes"
                                  multiline
                                  rows={4}
                                  onChange={(e) => {
                                    setAdminHostChanged(true)
                                    setAdminNotes(e.target.value)
                                  }}
                                  value={adminNotes}
                                  sx={{ marginRight: "13px", width: "100%" }}
                                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                                />
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={parkingStatus}
                                  label="Age"
                                  onChange={handleChange}
                                  sx={{ marginTop: "3vh" }}

                                >
                                  <MenuItem value={'pending'} style={{ textAlign: "center" }}>Pending</MenuItem>
                                  <MenuItem value={'active'}>Active</MenuItem>
                                  <MenuItem value={'paused'}>Paused</MenuItem>
                                  <MenuItem value={'deactivated'}>Deactivated</MenuItem>
                                </Select>

                                <Button style={{ margin: "1vw", textAlign: "center" }} onClick={handlePaymentUpdate}>Save</Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>

                      </CardBody>
                    </Card>
                  </Col>
                  {/* Maps */}
                  <>
                    {isLoaded ? (
                      <Col md="12">
                        <Card className="card-plain">
                          <CardHeader>
                            <CardTitle tag="h4" style={{
                              background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                              WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.3rem"
                            }}>Spot location</CardTitle>
                            <p className="category" style={{ fontSize: "1rem", marginTop: "3vh" }}>{spot.location.formal}</p>
                          </CardHeader>
                          <CardBody>
                            <Grid item lg={12} md={12} xs={12} style={{ width: "90%", minHeight: "40vh", margin: "3vh auto" }} container>
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                options={{
                                  backgroundColor: "#1F85DE",
                                  disableDoubleClickZoom: false,
                                  zoomControl: true,
                                  fullscreenControl: false,
                                  styles: exampleMapStyles,
                                  streetViewControl: false
                                }}
                                center={center}
                                zoom={11}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              >
                                {/* Child components, such as markers, info windows, etc. */}
                                <>
                                  {showMarker && (
                                    <MarkerF
                                      position={{ lat: markerLat, lng: markerLong }} />
                                  )}
                                  {/* Map over older ones */}
                                  {markers.map((mapType, index) => {
                                    console.log("Marker...", mapType)
                                    console.log(mapType.lng, mapType.lat, index)
                                    return (<MarkerF
                                      position={{ lat: mapType.lat, lng: mapType.lng }} key={index} />)
                                  })
                                  }
                                </>
                              </GoogleMap>
                            </Grid>
                          </CardBody>
                        </Card>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </>

                  {/* Users */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4" style={{
                          background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.3rem"
                        }}>Delegates</CardTitle>
                        <p className="category" style={{ fontSize: "1rem", marginTop: "3vh" }}>Users who can manage spot. &nbsp;<a onClick={() => setShowInvite(true)}><i className="tim-icons icon-simple-add" /></a></p>
                      </CardHeader>
                      
                      <CardBody>
                        {
                        // eslint-disable-next-line no-prototype-builtins
                        spot.hasOwnProperty('management') && (
                          <>
                            {// eslint-disable-next-line no-prototype-builtins
                            (spot.management.hasOwnProperty('delegates') || Object.keys(spot.management.delegates).length !== 0) ? (
                              <Table className="tablesorter" responsive>
                                <thead className="text-primary">
                                  <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(spot.management.delegates).map((key) => {
                                    return (

                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>{spot.management.delegates[key].role}</td>
                                        <td>
                                          {/* <a onClick={() => updateUser(users[key].user_id)}><i className="tim-icons icon-pencil" /></a> &nbsp; */}
                                          <a onClick={() => removeUser(key)}><i className="tim-icons icon-trash-simple" /></a>&nbsp;&nbsp;


                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              <p>There is no delegates linked yet</p>
                            )}
                          </>
                        )}


                      </CardBody>
                    </Card>
                  </Col>

                  {/* Other spots related */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4" style={{
                          background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                          WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 500, fontSize: "1.3rem"
                        }}>Related Spots</CardTitle>
                        <p className="category" style={{ fontSize: "1rem", marginTop: "3vh" }}>Spots who have the same owner. &nbsp;<a onClick={() => setShowInvite(true)}><i className="tim-icons icon-simple-add" /></a></p>
                      </CardHeader>
                      <CardBody>
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>
                              <th>Average</th>

                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(items).map((key) => (
                              <tr key={items[key].location.formal} onClick={() => {
                                console.log("Sending you fucker to :", items[key])
                                history.push({
                                  pathname: `/details/`,
                                  state: { spot: items[key] }
                                })
                              }}>
                                <td>{items[key].location.formal}</td>
                                <td> <Rating name="read-only" value={items[key]?.rating?.average || 0} readOnly /></td>

                              </tr>
                            ))}
                          </tbody>
                        </Table>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                {/* Invite User */}
                <Modal show={showInvite} onHide={handleClose} style={{ background: "rgba(20, 20, 20, 0.7)" }}>
                  <Modal.Header>
                    <Modal.Title>Invite User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={(theme.theme !== 'white-content') ? { backgroundColor: "#212529", color: "white" } : { backgroundColor: "white" }}>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Title for the business"
                          autoFocus
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Role</Form.Label>
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          style={(theme.theme !== 'white-content') ? { backgroundColor: "#212529", color: "white", width: "100%", justifyContent: "center" } : { backgroundColor: "white", width: "100%", justifyContent: "center" }}
                        >

                          <ToggleButton value="Viewer" aria-label="left aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Viewer
                          </ToggleButton>
                          <ToggleButton value="Admins" aria-label="centered" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Admins
                          </ToggleButton>
                          <ToggleButton value="Finance" aria-label="right aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                            Finance
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer style={(theme.theme !== 'white-content') ? { backgroundColor: "#212529", color: "white" } : { backgroundColor: "white" }}>
                    <Button variant="secondary" onClick={handleCloseInvite} style={{ marginLeft: "20%" }}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={sendInvite} style={{ marginRight: "20%" }}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>

              </>
            ) : (<>
              <Row >

                <Col md="12" >
                  <Card className="card-plain" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                  }} >
                    <CardHeader >
                      <CardTitle tag="h4">We could not find this spot..</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <p className="category">If this is a mistake please contact IT</p><br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>)}

          </div>
        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Business Overview</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>

                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default SpotOverview;
