import React, { useState, useEffect, useContext } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeContext } from "../../contexts/ThemeContext"
import { collection, query, getDocs, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function FAQ({ user }) {

  const theme = useContext(ThemeContext);

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [modalId, setModalID] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  // Add form
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");

  const [alignment, setAlignment] = useState('private');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addItem = async () => {

    let dataToSend = {
      "question": newTitle,
      "answer": newMessage,
      "type": alignment
    }
    const docRef = await addDoc(collection(db, firestoreKeys.FAQ), dataToSend);
    console.log("Document written with ID: ", docRef.id);
    if (docRef.id === undefined) {
      notify("tl", <h1>Added FAQ failed</h1>, "danger")
    } else {
      const tmpArray = [...items]
      let item = dataToSend
      item.id = docRef.id
      Object.preventExtensions(item);
      tmpArray.push(item)
      setItems(tmpArray)
      notify("tl", <p>Added FAQ</p>, "success")
    }
    setShow(false)

  };

  const editFAQ = async (faq) => {

    console.log("Updating",faq)
    setModalID(faq.id)
    setTitle(faq.question)
    setMessage(faq.answer)
    setAlignment(faq.type);
    setShowUpdate(true)
  };

  const updateFAQ = async () => {
    const docRef = doc(db, firestoreKeys.FAQ, modalId);
    let updatedFields = { answer: message, question: title, type: alignment}
    updateDoc(docRef, updatedFields)
      .then(docRef => {
        const tmpArray = items
        let indexToUpdate = tmpArray.find(item => item.id === modalId);
        indexToUpdate.answer = message
        indexToUpdate.question = title
        indexToUpdate.type = alignment
        setItems(tmpArray)
        setShowUpdate(false)
      })
      .catch(error => {
        console.log(error);
        notify("tl", <p>{error}</p>, "danger")
        setShowUpdate(false)
      })
  };

  const deleteFAQ = async (id) => {
    console.log("Deleting...")
    const docRef = doc(db, firestoreKeys.FAQ, id);
    deleteDoc(docRef)
      .then(docRef => {
        console.log("Delete")
        let tmpArray = items
        tmpArray = tmpArray.filter(item => item.id != id);
        console.log(tmpArray)
        setItems(tmpArray)
        notify("tl", <p>Removed FAQ</p>, "success")
        setShowUpdate(false)
      })
      .catch(error => {
        console.log("Delete Failed", error);
        notify("tl", <p>{error}</p>, "danger")
        setShowUpdate(false)
      })
  };


  useEffect(() => {
    const getContacts = async () => {
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.FAQ)));
      querySnapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      setIsLoaded(true)
    }
    getContacts()
  }, [])

  const renderDetailsButton = (params) => {
    return (
      <>
        <Button
          variant="primary"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            editFAQ(params.row)
          }}
        >
          Update
        </Button>
        <Button
          variant="primary"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            deleteFAQ(params.row.id)
          }}
        >
          Delete
        </Button>
      </>
    )
  }

  const columns = [
    {
      field: 'question',
      headerName: 'Question',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.question || ''}`,
    },
    {
      field: 'answer',
      headerName: 'Answer',
      description: 'This column has a value getter and is not sortable.',
      flex: 1,
      valueGetter: (params) =>
        `${params.row.answer || ''}`,
    },
    {
      field: 'type',
      headerName: 'Type',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.type || ''}`,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{fontSize: "1.5rem", background: 
"-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", 
WebkitBackgroundClip: 
"text", WebkitTextFillColor: 
"transparent", fontWeight: 500}}>FAQ</CardTitle>
                      <p className="category" style={{ fontSize: "1rem", marginTop: "3vh"}}>Messages to notify users of issues. &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%', marginTop: "3vh" }}>
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          style={{}}
                          className="text-cente tablesorter"
                          getRowId={(row) => row.id}

                        /> </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Create modal */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Create Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="question"
                      placeholder="Title for the contact"
                      autoFocus
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Answer</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Message to show users" onChange={(e) => setNewMessage(e.target.value)} />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      style={(theme.theme !== 'white-content') ? { backgroundColor: "#212529", color: "white", width: "100%", justifyContent: "center" } : { backgroundColor: "white", width: "100%", justifyContent: "center" }}
                    >

                      <ToggleButton value="private" aria-label="left aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Private
                      </ToggleButton>
                      <ToggleButton value="public" aria-label="centered" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Public
                      </ToggleButton>
                      <ToggleButton value="both" aria-label="right aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Both
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{ marginBottom: "3vh", marginLeft: "2vw"}}>
                  Close
                </Button>
                <Button variant="primary" onClick={addItem} style={{ marginBottom: "3vh", marginRight: "2vw"}}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
              <Modal.Header>
                <Modal.Title>Update Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="id"
                      autoFocus
                      readOnly
                      value={modalId}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label style={{ marginTop: "2vh"}}>Question</Form.Label>
                    <Form.Control
                      type="question"
                      autoFocus
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ marginTop: "2vh"}}>Answer</Form.Label>
                    <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label style={{ marginTop: "2vh"}}>State</Form.Label>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      style={(theme.theme !== 'white-content') ? { backgroundColor: "#212529", color: "white", width: "100%", justifyContent: "center" } : { backgroundColor: "white", width: "100%", justifyContent: "center" }}
                    >

                      <ToggleButton value="private" aria-label="left aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Private
                      </ToggleButton>
                      <ToggleButton value="public" aria-label="centered" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Public
                      </ToggleButton>
                      <ToggleButton value="both" aria-label="right aligned" style={(theme.theme !== 'white-content') ? { color: "white" } : {}}>
                        Both
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginBottom: "3vh", marginLeft: "2vw"}}>
                  Close
                </Button>
                <Button variant="primary" onClick={updateFAQ} style={{ marginBottom: "3vh", marginRight: "2vw"}}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

