

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import Button from 'react-bootstrap/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as Sentry from "@sentry/react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import Grid from '@mui/material/Grid';
import { ThemeContext } from "../../contexts/ThemeContext"
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../../services/firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { firestoreKeys } from '../../utils/constants';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


function SpotOverview(props) {

  let history = useHistory();

  const location = useLocation();
  console.log("State got:", location)
  const theme = useContext(ThemeContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN
  })

  const [user, setUser] = useState(location.state.user)
  const [host, setHost] = useState({})
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [delSpots, setDelSpots] = useState([])
  // Invite
  const [alignment, setAlignment] = React.useState('viewer');
  const [email, setEmail] = useState("");
  const [showInvite, setShowInvite] = useState(false);


  const containerStyle = {
    width: '100%',
    minHeight: '80%'
  };
  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updateUser = async () => {
    setShowInvite(true)

  };

  const removeUser = async (email) => {
    console.log("Removing user")
  }

  const sendInvite = async () => {
    console.log("Adding dependent")
  }

  useEffect(() => {
    let message = (<div>
      <div>
        Could not load corresponding data
      </div>
    </div>)


    const getRelatedSpots = async () => {
      console.log(user)
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.SPOTS), (where('owner', '==', user.id))));
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        tmpArray.push(doc.data())
      });
      setItems(tmpArray)
    }
    getRelatedSpots()
    setLoaded(true)

  }, [])


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {loaded ? (
        <>
          <div className="content">
            {user !== undefined ? (
              <>

                <Row>
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4" style={{ fontSize: "1.5rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", 
                        WebkitBackgroundClip: "text", 
                        WebkitTextFillColor: "transparent", fontWeight: 500 }}>User details</CardTitle>
                        <p className="category" style={{fontSize: "1rem", marginTop: "3vh"}}>Details linked. &nbsp;</p>


                      </CardHeader>
                      <CardBody>

                        <Form>
                          <Row>
                            <Col lg={4} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{fontSize: "1rem", color: "#000"}}>Name</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={user.person.firstName || ''}
                                  style={{backgroundColor: "#c7d3e233", borderRadius: "0.2vw"}}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={4} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{fontSize: "1rem", color: "#000"}}>Surname</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={user.person.lastName || ''}
                                  style={{backgroundColor: "#c7d3e233", borderRadius: "0.2vw"}}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label style={{fontSize: "1rem", color: "#000"}}>Email</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={user.person.email || ''}
                                  style={{backgroundColor: "#c7d3e233", borderRadius: "0.2vw"}}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>

                      </CardBody>
                    </Card>
                  </Col>
                  
                  {/*Host */}
                  {user.permissions &&  user.permissions.host && (
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4" style={{ fontSize: "1.3rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", 
                        WebkitBackgroundClip: "text", 
                        WebkitTextFillColor: "transparent", fontWeight: 500 }}>Spots hosted</CardTitle>
                          <p className="category" style={{ fontSize: "1rem", marginTop: "3vh"}}>Spots hosted by this user</p>
                        </CardHeader>
                        <CardBody>
                          <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                              <tr>
                                <th>Name</th>
                                <th>Average</th>

                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(items).map((key) => (
                                <tr key={items[key].location.formal} onClick={() => {
                                  history.push({
                                    pathname: '/details',
                                    state: { spot: items[key] }
                                  })
                                }}>
                                  <td>{items[key].location.formal}</td>
                                  <td> <Rating name="read-only" value={items[key]?.rating?.average || 0}  readOnly /></td>

                                </tr>
                              ))}
                            </tbody>
                          </Table>

                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {/* Delegate */}
                  {/* {( user.permissions.delegate) && (
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4">Spots Delegated</CardTitle>
                          <p className="category">Spots this user has delivfgate access over</p>
                        </CardHeader>
                        <CardBody>
                          <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                              <tr>
                                <th>Name</th>
                                <th>Average</th>

                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(delSpots).map((key) => (
                                <tr key={delSpots[key].location.formal} onClick={() => {
                                  history.push({
                                    pathname: '/details',
                                    state: { spot: delSpots[key] }
                                  })
                                }}>
                                  <td>{delSpots[key].location.formal}</td>
                                  <td> <Rating name="read-only" value={delSpots[key].rating.average} readOnly /></td>

                                </tr>
                              ))}
                            </tbody>
                          </Table>

                        </CardBody>
                      </Card>
                    </Col>
                  )} */}
                  
                </Row>



              </>
            ) : (<>
              <Row >

                <Col md="12" >
                  <Card className="card-plain" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                  }} >
                    <CardHeader >
                      <CardTitle tag="h4">We could not find this spot..</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <p className="category">If this is a mistake please contact IT</p><br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>)}

          </div>
        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">User Overview</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>

                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

export default SpotOverview;
