import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import { Input, IconButton, InputAdornment } from '@mui/material';
import "../../assets/css/black-dashboard-react.css"
import { AuthContext } from "../../contexts/AuthContext"
import Form from 'react-bootstrap/Form';
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { Stepper, Step, StepLabel, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import Button from 'react-bootstrap/Button';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 90deg, #964cff, #bb2cc0)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 90deg, #964cff, #bb2cc0)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(90deg, #964cff, #964cff 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 90deg, #964cff, #bb2cc0 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
        4: <SettingsIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

function ColorlibStepIconRating(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <SettingsIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

export default function RiskEngine({ props }) {

    const { user, setUser } = React.useContext(AuthContext)
    const [IsLoaded, setIsLoaded] = useState(true);

    const [values, setValues] = React.useState({
        password: user.accessToken,
        showPassword: false,
    });

    const [selectedOption, setSelectedOption] = useState('');
    const [userReport, setUserReports] = useState(false);
    const [ratingReport, setRatingReport] = useState(false);

    const applyReportBlock = async () => {
        const docRef = doc(db, firestoreKeys.GLOBAL, "rules");
        let updatedFields = { "reportUnapproved.counter": parseInt(totalReports), "reportsApproved.counter": parseInt(adminReports) }
        updateDoc(docRef, updatedFields)
            .then(docRef => {

            })
            .catch(error => {
                console.log(error);
                // notify("tl", <p>{error}</p>, "danger")
            })

        let dataToSend = {
            "date": new Date(),
            "actions": "risk_engine",
            "user": user.email,
            "reportUnapproved": parseInt(totalReports),
            "reportsApproved": parseInt(adminReports)
        }
        await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
    }

    // Reports
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Current', 'Total Reports', 'Admin Reports', 'Overview'];
    const [totalReports, setTotalReports] = useState();
    const [adminReports, setAdminReports] = useState();
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleTotalReportsChange = (event) => {
        setTotalReports(event.target.value);
    };

    const handleAdminReportsChange = (event) => {
        setAdminReports(event.target.value);
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2>Current Overview</h2>
                            <p>Total Reports: {totalReports}</p>
                            <p>Admin Reports: {adminReports}</p>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>User Reports</Form.Label>
                            <Form.Control
                                autoFocus
                                type="number"
                                value={totalReports}
                                onChange={handleTotalReportsChange}
                            />
                        </Form.Group>

                    </div>
                );
            case 2:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Admin Reports</Form.Label>
                            <Form.Control
                                autoFocus
                                type="number"
                                value={adminReports}
                                onChange={handleAdminReportsChange}
                            />
                        </Form.Group>
                    </div>
                );
            case 3:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2>Overview</h2>
                            <p>Total Reports: {totalReports}</p>
                            <p>Admin Reports: {adminReports}</p>
                            <Button variant="contained" color="primary" onClick={applyReportBlock}>
                                Apply
                            </Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // Rating Rules
    const [activeRatingStep, setRatingActiveStep] = useState(0);
    const ratingSteps = ['Current', 'Rating Drop', 'Overview'];
    const [ratingDropLimit, setRatingDropLimit] = useState();

    const applyRatingLimit = async () => {
        const docRef = doc(db, firestoreKeys.GLOBAL, "rules");
        let updatedFields = { "ratingRemoval.counter": parseInt(ratingDropLimit) }
        updateDoc(docRef, updatedFields)
            .then(docRef => {

            })
            .catch(error => {
                console.log(error);
                // notify("tl", <p>{error}</p>, "danger")
            })

        let dataToSend = {
            "date": new Date(),
            "actions": "risk_engine",
            "user": user.email,
            "ratingRemoval": parseInt(ratingDropLimit),
        }
        await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
    }
    const handleNextLimit = () => {
        setRatingActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackLimit = () => {
        setRatingActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleTotalReportsChangeLimit = (event) => {
        setRatingDropLimit(event.target.value);
    };


    const renderStepContentLimit = (step) => {
        switch (step) {
            case 0:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2>Current Overview</h2>
                            <p>Rating limit: {ratingDropLimit}</p>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Rating limit</Form.Label>
                            <Form.Control
                                autoFocus
                                type="number"
                                value={ratingDropLimit}
                                onChange={handleTotalReportsChangeLimit}
                            />
                        </Form.Group>

                    </div>
                );
            case 2:
                return (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2>Overview</h2>
                            <p>Rating limit: {ratingDropLimit}</p>
                            <Button variant="contained" color="primary" onClick={applyRatingLimit}>
                                Apply
                            </Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // Notifications
    const notificationAlertRef = React.useRef(null);
    const notify = (place, message, type) => {
        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getDetails = async () => {
            console.log(user)
            const docRef = doc(db, firestoreKeys.GLOBAL, "rules");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                const rules = docSnap.data()
                setTotalReports(rules.reportUnapproved.counter)
                setAdminReports(rules.reportsApproved.counter)
                setRatingDropLimit(rules.ratingRemoval.counter)
              } else {
                // Sentry.captureException("No such document for user: ", spot.owner);
                notify("tl", "Cant find data", "danger")
              }
            // let dataToSend = {
            //     "date": new Date(),
            //     "actions": "access_token",
            //     "user": user.email
            // }
            // const docRef = await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
        }
        getDetails()
    }, [])


    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ? (
                <>
                    <>
                        <div className="content">
                            <Row>
                                <Col md="12">
                                    <Card className="card-plain">
                                        <CardHeader>
                                            <CardTitle tag="h4" style={{
                                                fontSize: "1.4rem", background:
                                                    "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                                                WebkitBackgroundClip:
                                                    "text", WebkitTextFillColor:
                                                    "transparent", fontWeight: 500
                                            }}>Payments</CardTitle>
                                            <p className="category" style={{ fontSize: "1rem", marginTop: "3vh" }}>Globals default payment settings</p>
                                        </CardHeader>
                                        <CardBody>
                                            <div>
                                                {/* User report blocking */}
                                                <div> <Button variant="primary" onClick={() => setUserReports(!userReport)} style={{ marginRight: "20%" }}>
                                                    Report blocks</Button>
                                                </div>

                                                {userReport && (
                                                    <div style={{ width: '100%', marginTop: "3vh", marginBottom: "3vh" }}>

                                                        <>
                                                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '3vh', textAlign: 'center' }}>
                                                                <p>This is the risk engine rule that will automatically block a sport based on reports</p>
                                                            </div>
                                                            <Stepper
                                                                alternativeLabel
                                                                activeStep={activeStep}
                                                                connector={<ColorlibConnector />}
                                                            >
                                                                {steps.map((label) => (
                                                                    <Step key={label}>
                                                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                                                    </Step>
                                                                ))}
                                                            </Stepper>

                                                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '3vh' }}>
                                                                {renderStepContent(activeStep)}
                                                                <br />
                                                                <div style={{ marginTop: '3vh', display: 'flex', alignItems: 'center' }}>
                                                                    <Button disabled={activeStep === 0} onClick={handleBack}>
                                                                        Back
                                                                    </Button>
                                                                    {activeStep < steps.length - 1 && (
                                                                        <Button variant="contained" color="primary" onClick={handleNext}>
                                                                            Next
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>

                                                )}

                                                {/* User rating blocking */}
                                                <div> <Button variant="primary" onClick={() => setRatingReport(!ratingReport)} style={{ marginRight: "20%" }}>
                                                    Rating blocks</Button>
                                                </div>
                                                <Stack sx={{ width: '100%' }} spacing={4}>

                                                    {/* Render the second Stepper */}
                                                    {ratingReport && (
                                                        <div style={{ width: '100%', marginTop: "3vh", marginBottom: "3vh" }}>

                                                        <>
                                                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '3vh', textAlign: 'center' }}>
                                                                <p>This is the risk engine rule that will automatically block a sport based on reports</p>
                                                            </div>
                                                            <Stepper
                                                                alternativeLabel
                                                                activeStep={activeRatingStep}
                                                                connector={<ColorlibConnector />}
                                                            >
                                                                {ratingSteps.map((label) => (
                                                                    <Step key={label}>
                                                                        <StepLabel StepIconComponent={ColorlibStepIconRating}>{label}</StepLabel>
                                                                    </Step>
                                                                ))}
                                                            </Stepper>

                                                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '3vh' }}>
                                                                {renderStepContentLimit(activeRatingStep)}
                                                                <br />
                                                                <div style={{ marginTop: '3vh', display: 'flex', alignItems: 'center' }}>
                                                                    <Button disabled={activeRatingStep === 0} onClick={handleBackLimit}>
                                                                        Back
                                                                    </Button>
                                                                    {activeRatingStep < ratingSteps.length - 1 && (
                                                                        <Button variant="contained" color="primary" onClick={handleNextLimit}>
                                                                            Next
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                    )}
                                                </Stack>


                                                {/* Render additional workflow components based on the selected option */}
                                                {selectedOption && (
                                                    <div>
                                                        {/* Additional workflow components */}
                                                    </div>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </>



                </>)
                : (
                    <div className="content">
                        <Row>

                            <Col md="12">
                                <Card className="card-plain">
                                    <CardBody style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100vh'
                                    }}>
                                        <ThreeCircles
                                            height="100"
                                            width="100"
                                            color="#9753f7"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor=""
                                            innerCircleColor="grey"
                                            middleCircleColor=""
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>


                )
            }

        </>
    );
}

