export const firestoreKeys = Object.freeze({
  TRANSACTIONS: 'transactions',
  USERS: 'users',
  SPOTS: 'spots',
  REPORTS: 'reports',
  FEEDBACK: 'feedback',
  FAQ: 'faq',
  BOOKINGS: 'bookings',
  CONTACTUS: 'contactus',
  BANNERS: 'banners',
  AUDIT_TRAIL: 'audit_trail',
  GLOBAL: 'global'
});

export const dateFormat = 'DD/MM/YYYY';
export const timeFormat = 'HH:mm';
export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
