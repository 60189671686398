import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

export default function Transactions() {

  let history = useHistory();

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [bannerToUpdate, setBannerToUpdate] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("")
  // Add form
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");


  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getContacts = async () => {
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.TRANSACTIONS)));
      querySnapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      console.log(items)
      setIsLoaded(true)
    }
    getContacts()
  }, [])

  const renderDetailsButton = (params) => {
    return (
      <>
        <Button
          variant="primary"
          color="primary"
          size="small"
          style={{ marginLeft: 16, width: "50%", display: "flex", justifyContent: "center" }}
          onClick={() => {
            history.push({
              pathname: '/transaction',
              state: { transaction: params.row }
            })
          }}

        >
          Info
        </Button>

      </>
    )
  }


  const ActiveCheckBox = (params) => {
    return (
      <>
        {params.row.active ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )

        }


      </>
    )
  }

  const columns = [
    {
      field: 'amount',
      headerName: 'Amount',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.amount/100|| ''}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.status|| ''}`,
    },
    {
      field: 'pid',
      headerName: 'Paystack ID',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.pid || ''}`,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{
                        fontSize: "1.5rem", background:
                          "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                        WebkitBackgroundClip:
                          "text", WebkitTextFillColor:
                          "transparent", fontWeight: 500
                      }}>Transactions</CardTitle>
                      <p className="category" style={{ fontSize: "1rem", marginTop: "2vh" }}>Transactions &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%', marginTop: "3vh" }}>
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={50}
                          rowsPerPageOptions={[50, 150, 300]}
                          style={{}}
                          className="text-cente tablesorter"
                          getRowId={(row) => row.id}

                        /> </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

           
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

