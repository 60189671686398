import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { Input, IconButton, InputAdornment } from '@mui/material';
import "../../assets/css/black-dashboard-react.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthContext } from "../../contexts/AuthContext";
import Button from 'react-bootstrap/Button';
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDoc, doc, updateDoc, addDoc } from "firebase/firestore";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";

export default function Global({ props }) {
    const { user, setUser } = React.useContext(AuthContext);
    const [IsLoaded, setIsLoaded] = useState(true);
    const [paymentValue, setPaymentValue] = useState(15);
    const [referralValue, setReferralValue] = useState(10);
    const [isPaymentChanged, setIsPaymentChanged] = useState(false);
    const [isReferralChanged, setIsReferralChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const getDetails = async () => {
            console.log(user)
        }
        getDetails()
    }, []);

    const handleSavePayment = async () => {
        try {
            setIsSaving(true);
            const docRef = doc(db, firestoreKeys.GLOBAL, "split");
            let updatedFields = { "host": parseInt(paymentValue) }
            updateDoc(docRef, updatedFields)
                .then(async docRef => {
                    setIsPaymentChanged(false);
                    setIsSaving(false);
                    let dataToSend = {
                        "date": new Date(),
                        "actions": "global",
                        "user": user.email,
                        "split": parseInt(paymentValue)
                    }
                    await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
                })
                .catch(error => {
                    console.log(error);
                    setIsSaving(false);
                    // notify("tl", <p>{error}</p>, "danger")
                })
                
        } catch (error) {
            console.error('Error saving payment:', error);
            setIsSaving(false);
        }
    };

    const handleSaveReferral = async () => {
        try {
            setIsSaving(true);
            // New
            const docRef = doc(db, firestoreKeys.GLOBAL, "referral");
            let updatedFields = { "total": parseInt(referralValue) }
            updateDoc(docRef, updatedFields)
                .then(async docRef => {
                    setIsReferralChanged(false);
                    setIsSaving(false);
                    let dataToSend = {
                        "date": new Date(),
                        "actions": "global",
                        "user": user.email,
                        "split": parseInt(referralValue)
                    }
                    await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
                })
                .catch(error => {
                    console.log(error);
                    setIsSaving(false);
                    // notify("tl", <p>{error}</p>, "danger")
                })
                
        } catch (error) {
            console.error('Error saving referral:', error);
            setIsSaving(false);
        }
    };

    return (
        <>
            {IsLoaded ? (
                <>
                    <div className="content">
                        <Row>
                            <Col md="12">
                                <Card className="card-plain">
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{
                                            fontSize: "1.4rem", background:
                                                "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                                            WebkitBackgroundClip:
                                                "text", WebkitTextFillColor:
                                                "transparent", fontWeight: 500
                                        }}>Payments</CardTitle>
                                        <p className="category" style={{ marginTop: "3vh" }}>Globals default payment settings</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <Input
                                                type="text"
                                                value={paymentValue}
                                                onChange={(e) => {
                                                    setPaymentValue(e.target.value);
                                                    setIsPaymentChanged(true);
                                                }}
                                            />
                                            {isPaymentChanged && (
                                                <Button  disabled={isSaving} onClick={handleSavePayment} style={{margin: "1vw", textAlign: "center"}}>Save</Button>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="card-plain">
                                    <CardHeader>
                                        <CardTitle tag="h4" style={{
                                            fontSize: "1.2rem", background:
                                                "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
                                            WebkitBackgroundClip:
                                                "text", WebkitTextFillColor:
                                                "transparent", fontWeight: 500
                                        }}>Referrals</CardTitle>
                                        <p className="category" style={{ fontSize: "1rem", marginTop: "3vh" }}>Global Referrals settings</p>
                                    </CardHeader>
                                    <CardBody>
                                        <div>
                                            <Input
                                                type="text"
                                                value={referralValue}
                                                onChange={(e) => {
                                                    setReferralValue(e.target.value);
                                                    setIsReferralChanged(true);
                                                }}
                                            />
                                            {isReferralChanged && (
                                                <button onClick={handleSaveReferral} disabled={isSaving}>
                                                    Save Referral
                                                </button>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-plain">
                                <CardBody style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100vh'
                                }}>
                                    <ThreeCircles
                                        height="100"
                                        width="100"
                                        color="#9753f7"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor=""
                                        innerCircleColor="grey"
                                        middleCircleColor=""
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}