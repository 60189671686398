import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useHistory } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

export default function ContactUs() {
  let history = useHistory();

  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [modalId, setId] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [datePublished, setDatePublished] = useState("");
  // Add form
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const editContactView = async (id) => {


  };

  const activeContact = async (id) => {

  };

  const deleteContact = async (id) => {
  }

  useEffect(() => {
    const getContacts = async () => {
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.USERS)));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      setIsLoaded(true)
    }
    getContacts()
  }, [])

  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="primary"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => {
          history.push({
            pathname: `/user`,
            state: { user: params.row }
          })
        }}
      >
        Info
      </Button>
    )
  }

  const AdminCheckBox = (params) => {
    return (
      <>
        {params.row.permissions && params.row.permissions.admin ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )}
      </>
    );
  }

  const HostCheckbox = (params) => {
    console.log("In box")
    console.log( params.row.permissions?.admin)
    return (
      <>
         {params.row.permissions && params.row.permissions.host ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )

        }


      </>
    )
  }
  const UserCheckbox = (params) => {
    return (
      <>
         {params.row.permissions && params.row.permissions.user ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )

        }


      </>
    )
  }

  const columns = [
    {
      field: 'Email',
      headerName: 'Title',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.person.email || ''}`,
    },
    {
      field: 'Admin',
      headerName: 'Admin',
      flex: 1,
      renderCell: AdminCheckBox,
      disableClickEventBubbling: true,
    },
    {
      field: 'Host',
      headerName: 'Host',
      flex: 1,
      renderCell: HostCheckbox,
      disableClickEventBubbling: true,
    },
    {
      field: 'User',
      headerName: 'User',
      flex: 1,
      renderCell: UserCheckbox,
      disableClickEventBubbling: true,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{fontSize: "1.5rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", 
                        WebkitBackgroundClip: "text", 
                        WebkitTextFillColor: "transparent", fontWeight: 500 }}>Users</CardTitle>
                      <p className="category" style={{ fontSize: "1rem", marginTop: "2vh"}}>List of users on system.</p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%', marginTop: "3vh" }}>
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          style={{}}
                          className="text-cente tablesorter"
                          getRowId={(row) => row.id}

                        /> </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>


            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
              <Modal.Header>
                <Modal.Title>Update Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="id"
                      autoFocus
                      readOnly
                      value={modalId}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Last published</Form.Label>
                    <Form.Control
                      type="date_published"
                      autoFocus
                      value={datePublished}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoFocus
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUpdate}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleCloseUpdate}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

